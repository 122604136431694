<template>
  <v-container class="pt-0 pb-0 pr-0">
    <v-fab-transition>
      <v-btn
              fixed
              dark
              fab
              bottom
              right
              class="mr-3"
              color="primary"
              v-show="isScrolling"
              v-scroll="onScroll"
              @click="$vuetify.goTo(0)"
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fab-transition>

  <v-app-bar
    id="app-bar"
    dark
    class="pt-3 mx-auto"
    :fixed="!isHome"
    :flat="!isScrolling"
    :color="!isScrolling ? 'transparent' : '#000'"
    v-scroll="onScroll"
    max-width="1200px"
    :height="isHome ? 36 : 72"
  >
    <router-link
      v-if="!isHome"
      id="home-link"
      to="/"
    >
      <v-img
        :src="require('@/assets/sticker_logo.png')"
        class="mr-3 xhidden-xs-only"
        contain
        max-width="80"
        width="100%"
      />
    </router-link>

    <v-spacer></v-spacer>
    <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        v-for="item in itemshome"
        :key="item.text"
        :href="item.to"
        style="font-size: 19px;"
        text
        :color="isHome ? 'primary' : 'secondary'"
      >
        <span v-text="item.text" />
      </v-btn>
      <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-for="item in itemsmenu"
            :key="item.text"
            style="font-size: 19px"
            text
            v-bind="attrs"
            v-on="on"
            :color="isShow ? 'primary' : 'secondary'"
          >
            <span v-text="item.text" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in shows"
            :key="i"
            :href="item.to"
          >
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-for="item in items"
        :key="item.text"
        :href="item.to"
        style="font-size: 19px;"
        text
        :color="isAbout && item.text === 'About' ? 'primary' : 'secondary'"
      >
        <span v-text="item.text" />
      </v-btn>
    </v-toolbar-items>
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />
  </v-app-bar>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-model="drawer"
  >
    <v-list
      color="black"
      shaped
    >
      <v-list-item
        v-for="item in itemshome"
        :key="item.text"
        :href="item.to"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.text" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(item,i) in shows"
        :key="i"
        :href="item.to"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.text" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="item in items"
        :key="item.text"
        :href="item.to"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  </v-container>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'

  export default {
    name: 'PageAppBar',

    data: () => ({
      isScrolling: false,
      drawer: null,
       shows: [
          { to: "/bone-valley", text: "Bone Valley"},
          { to: "/earwitness", text: "Earwitness"},
          { to: "/erased", text: "Erased: The Murder of Elma Sands"},
          { to: "/false-confessions", text: "False Confessions"},
          { to: "/junk-science", text: "Junk Science"},
          { to: "/righteous-convictions", text: "Righteous Convictions with Jason Flom"},
          { to: "/war-on-drugs", text: "The War on Drugs"},
          { to: "/with-jason-flom", text: "Wrongful Conviction"},
          { to: "/with-lauren-bright-pacheco", text: "Wrongful Conviction with Lauren Bright Pacheco"},
          { to: "/with-maggie-freleng", text: "Wrongful Conviction with Maggie Freleng"},
       ],
        itemshome: [
          { to: "/", text: "Home"},
        ],
        itemsmenu: [
          { to: "/shows", text: "Shows"},
        ],
        items: [
          { to: "/awards", text: "Awards"},
          { to: "/about", text: "About"},
          //{ to: "/store", text: "Store"},
        ],
    }),

    computed: {
      name: get('route/name'),
      isHome () {
        return this.$route.name === 'Home'
      },
      isAbout () {
        return this.$route.name === 'About'
      },
      isShow () {
        return this.$route.name === 'Episodes'
      },
    },
    methods: {
       onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 100
      }
    }
  }
</script>
<style lang="sass">
  #app-bar .v-btn
    text-transform: none
</style>
